import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import spiritualImg from "../../../Assets/spiritual.png";
import dateImg from "../../../Assets/Date.png";
import locationImg from "../../../Assets/Location.png";
import { useNavigate, useParams } from "react-router-dom";
import { ApiCalling } from "../../../Services/Pool";
import { showToastWarning } from "../../../Utilities/ToastUtils";
import useGetLabels from "../../CustomHookes/useGetLabels";
import SomethingWrong from "../PageNotFound/SomethingWrong";
import PageDistrict from "../PageNotFound/PageDistrict";
import ReuseCode from "../Register/ReuseComponent/ReuseCode";
import ButtonToggle from "../../CustomHookes/ButtonToggle";

const EventPage = () => {
  const navigate = useNavigate();
  const [eventData, setEventData] = useState([]);
  const [registerData, setRegiaterData] = useState([]);
  const [helpnumber, setHelpNumber] = useState([]);
  const [slotData, setSlotData] = useState([]);
  // for language changes
  // const [selectedLanguage, setSelectedLanguage] = useState("mr");
  const [activeKey, setActiveKey] = useState("mr");

  const [loader, setLoader] = useState(false);

  const [apiError, setApiError] = useState(false);

  const [selectedSlot, setSelectedSlot] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(
    slotData?.length <= 1
  );
  const [isSlotSelected, setIsSlotSelected] = useState(false);
  const [showDistrictWarning, setSDW] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));
  const localToken = JSON.parse(localStorage.getItem("token"))

  const [token, setToken] = useState("")


  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("language") ?? 'mr');

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  useEffect(() => {
    const lang = localStorage.getItem("language");
    if (selectedLanguage == null) return;
    if (!lang) localStorage.setItem("language", selectedLanguage)
  }, [selectedLanguage])

  useEffect(() => {
    getToken()
    getEventData();
  }, [token]);

  useEffect(() => {
    (async () => {
      if (localToken !== null) {
        const toNavigate = await getEventData(localToken);
        if (toNavigate) { navigate(`/event-details/${localToken}/${selectedLanguage}`) }
        else {
          localStorage.clear();
          window.location.reload();
        }
      }
    })();

  }, [])

  // for call custom hook
  const { getAllLabels, setApiLabelError, apiLabelError } = useGetLabels();

  let eneventLabels, hneventLabels, mreventLabels;

  if (
    getAllLabels !== undefined &&
    getAllLabels !== null &&
    getAllLabels.length > 0 &&
    getAllLabels &&
    getAllLabels.length > 0
  ) {
    const { en, hn, mr } = getAllLabels[0];

    if (en && hn && mr) {
      eneventLabels = en.eventLabels;
      hneventLabels = hn.eventLabels;
      mreventLabels = mr.eventLabels;
    }
  }


  // get token
  const getToken = async () => {
    setLoader(true);
    try {
      const response = await fetch(
        `${ApiCalling.base_url_v1}${ApiCalling.paths.getToken}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const responseData = await response.json();
      setToken(responseData)
    } catch (error) {
      setLoader(false);
      setApiError(true);
      setApiLabelError(true);
    }
  };



  // getevent details
  const getEventData = async (localToken = null) => {
    setLoader(true);
    try {
      const response = await fetch(
        `${ApiCalling.base_url_v1}${ApiCalling.paths.getEventForUser}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json", authorization: localToken ?? token },
        }
      );
      const responseData = await response.json();
      if (responseData?.status == 401) {
        setSDW(true);
        return false;
      } else {
        setEventData(responseData?.eventData);
        setRegiaterData(responseData?.registerData);
        setSlotData(responseData?.slots);
        setHelpNumber(responseData?.helpNumbers);
        setLoader(false);
        return true;
      }
    } catch (error) {
      setLoader(false);
      setApiError(true);
      setApiLabelError(true);
      return true;
    }
  };


  // date conversion
  const getFormattedDate = (date) => {
    if (!date) {
      console.error("Invalid date:", date);
      return null;
    }

    const [day, month, year] = date.split("-").map(Number);

    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      console.error("Invalid date components:", date);
      return null;
    }

    const parsedDate = new Date(year, month - 1, day);

    if (isNaN(parsedDate.getTime())) {
      console.error("Invalid parsed date:", date);
      return null;
    }

    const formattedDate = parsedDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  if (showDistrictWarning) {
    return <div>Loading...</div>;
  }




  return (
    <>

      <div className="mt-3">
        {loader ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spinner size="lg" />
          </div>
        ) : apiError || apiLabelError ? (
          <SomethingWrong />
        ) : (
          <>
            <div
              className="mainContainer"
              style={{ overflowX: "scroll", height: "80vh" }}
            >
              <div className="my-3 d-flex justify-content-between">

                <ButtonToggle selectedLanguage={selectedLanguage} onLanguageChange={handleLanguageChange} />

                {/* <ButtonGroup
                  aria-label="Basic example"
                  style={{
                    border: "1px solid black",
                    borderRadius: "50px",
                    overflow: "hidden",
                  }}
                >
                  <Button
                    variant="light"
                    className="border-right"
                    active={activeKey === "mr"}
                    onClick={() => handleButtonClick("mr")}
                    style={{
                      backgroundColor:
                        activeKey === "mr" ? "#FD6E47" : "#FCFFFF",
                      color: activeKey === "mr" ? "#FCFFFF" : "#000",
                      borderRight: "1px solid black",
                    }}
                  >
                    MR
                  </Button>
                  <Button
                    variant="light"
                    className="border-right"
                    active={activeKey === "hn"}
                    onClick={() => handleButtonClick("hn")}
                    style={{
                      backgroundColor:
                        activeKey === "hn" ? "#FD6E47" : "#FCFFFF",
                      color: activeKey === "hn" ? "#FCFFFF" : "#000",
                      borderRight: "1px solid black",
                    }}
                  >
                    HN
                  </Button>
                  <Button
                    variant="light"
                    className="border-right"
                    active={activeKey === "en"}
                    onClick={() => handleButtonClick("en")}
                    style={{
                      backgroundColor:
                        activeKey === "en" ? "#FD6E47" : "#FCFFFF",
                      color: activeKey === "en" ? "#FCFFFF" : "#000",
                      borderLeft: "1px solid black",
                    }}
                  >
                    EN
                  </Button>
                </ButtonGroup> */}

                {/* <ReuseCode
                  onLanguageChange={handleLanguageChange} /> */}
                {user === null ? (
                  <Button
                    style={{
                      background: "#FD6E47",
                      width: 100,
                      marginRight: 10,
                      padding: 10,
                      border: 'none'
                    }}
                    onClick={() => {
                      navigate("/mobilenumber");
                    }}
                  >
                    Login <Icon.ArrowBarRight size={20} />
                  </Button>
                ) : null}
              </div>
              <div className="imageContainer">
                <img
                  src={eventData?.image}
                  alt="image"
                  style={{
                    width: "100%",
                    borderRadius: "20px",
                    marginTop: "10px",
                  }}
                />
              </div>

              <div className="eventContainer">
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "28.8px",
                  }}
                >
                  {/* {lang === "mr" ? eventData?.mar?.eventName : lang === "hn" ? eventData?.hn?.eventName : eventData?.en?.eventName} */}
                  {selectedLanguage === "mr"
                    ? eventData?.mar?.eventName
                    : selectedLanguage === "hn"
                      ? eventData?.hn?.eventName
                      : eventData?.en?.eventName}
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5%",
                  }}
                >
                  <img src={dateImg} className="contentImg" />
                  <div>
                    <h6
                      style={{ fontSize: "14px", fontWeight: 400, margin: 0 }}
                    >
                      {getFormattedDate(eventData?.StartDate)} -{" "}
                      {getFormattedDate(eventData?.EndDate)}
                    </h6>
                  </div>
                </div>

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <img src={locationImg} className="contentImg" />
                  <div>
                    <h6
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19.07px",
                      }}
                    >
                      {/* {lang === "mr" ? eventData?.mar?.address : lang === "hn" ? eventData?.hn?.address : eventData?.en?.address} */}
                      {selectedLanguage === "mr"
                        ? eventData?.mar?.address
                        : selectedLanguage === "hn"
                          ? eventData?.hn?.address
                          : eventData?.en?.address}
                    </h6>
                    <a
                      href={eventData?.locationUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Take me to the location
                    </a>
                  </div>
                </div>

                <div
                  style={{
                    backgroundColor: "#EBF3FC",
                    padding: "15px",
                    borderRadius: "10px",
                    marginTop: "15px",
                  }}
                >
                  <h6
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "34px",
                    }}
                  >
                    {/* {lang === "mr" ? mreventLabels?.aboutEvent : lang === "hn" ? hneventLabels?.aboutEvent : eneventLabels?.aboutEvent} */}
                    {selectedLanguage === "mr"
                      ? mreventLabels?.aboutEvent
                      : selectedLanguage === "hn"
                        ? hneventLabels?.aboutEvent
                        : eneventLabels?.aboutEvent}
                  </h6>

                  <div>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "22px",
                      }}
                    >
                      {/* {lang === "mr" ? eventData?.mar?.description : lang === "hn" ? eventData?.hn?.description : eventData?.en?.description} */}
                      {selectedLanguage === "mr"
                        ? eventData?.mar?.description
                        : selectedLanguage === "hn"
                          ? eventData?.hn?.description
                          : eventData?.en?.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="d-flex justify-content-center my-4"
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
                marginBottom: "40px",
                position: "fixed",
                bottom: 0,
                left: "20px",
                zIndex: "0",
              }}
            >
              <button className="primaryBtn" onClick={() => navigate("/mobilenumber")}>
                {registerData?.status === "Confirmed"
                  ? selectedLanguage === "mr"
                    ? mreventLabels?.EventBooked
                    : selectedLanguage === "hn"
                      ? hneventLabels?.EventBooked
                      : eneventLabels?.EventBooked
                  : selectedLanguage === "mr"
                    ? mreventLabels?.BookEvent
                    : selectedLanguage === "hn"
                      ? hneventLabels?.BookEvent
                      : eneventLabels?.BookEvent}
                {/* {(registerData?.status === "Confirmed")
                      ? lang === "mr"
                        ? mreventLabels?.EventBooked
                        : lang === "hn"
                          ? hneventLabels?.EventBooked
                          : eneventLabels?.EventBooked

                      : lang === "mr"
                        ? mreventLabels?.BookEvent
                        : lang === "hn"
                          ? hneventLabels?.BookEvent
                          : eneventLabels?.BookEvent} */}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EventPage;
