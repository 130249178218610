
import Dada from "../../../../Assets/satsang.svg"
import Shrim from "../../../../Assets/vishava.svg"
import Tambe from "../../../../Assets/TambeFrame.png"

import Flower from "../../../../Assets/image 17844.png"
import Vishava from "../../../../Assets/vishwa-wellness-logo 1.svg"
import Satsang from "../../../../Assets/TSF_logo_white_600px 2.svg"

export const pillarsData = [
    {
        src: Dada,
        description: 'A recipient of Padma Bhushan Award, Sri M is an Indian Yogi, Spiritual Guide, Orator, and Educationist. He is a disciple of many esteemed gurus from the region of Himalayas. The founder of Satsang Foundation, he believes that to achieve wellbeing, one needs to transcend the outer shell and nurture the innate goodness.',
        image: Satsang
    },
    {
        src: Tambe,
        description: `A recipient of Padma Shri, Dr. Balaji Tambe is an Ayurvedic Doctor,
                Spiritual Guide, and also an expert in Music and Yoga Therapy. He is the founder of
                Atmasantulana Village, located in Pune. He is of the belief that –
                “Ayurveda is not merely a medical solution; instead, it is a way of life to achieve a healthy mind, body, and soul.”`,
        image: Flower
    },
    {
        src: Shrim,
        description: 'Dr. Purushottam Rajimwale was born in Shivpuri (Akkalkot), in the family of Param Sadguru Gajanan Maharaj. He is the global head of Shri Swami Samartha Paduka Math (Shri Guru Mandir) and Vishwa Foundation. He is on the mission of healing humanity and creating a better planet using ‘Agnihotra’, a proven Vedic process, as a way of life.',
        image: Vishava
    }
]