
import React, { useEffect, useRef, useState } from 'react';
import sriimg from "../../../Assets/image 17843.png";
import familyImg from "../../../Assets/Isolation_Mode.png";
import * as Icon from "react-bootstrap-icons";
import groupImg from "../../../Assets/en_badge_web_generic.png";
import SakalLogo from "../../../Assets/sakal-digital-2 1.png"
import OwlCarousel from 'react-owl-carousel';
import logoImage from "../../../Assets/landing/logo-orange-transparent.png";

import eventBG from "../../../Assets/eventBg.svg";
import eventIMG from "../../../Assets/eventImg.svg"
import Location from "../../../Assets/Location.svg"
import Calender from "../../../Assets/Calendar.svg"
import "./LandingPage/Landing.css"
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { Pillars } from './components/Pillars';

import { FeaturesCarousel } from './components/Features';
import { EventInformation } from '../../LandingPageComponents/EventInformation';
import { CTA } from '../../LandingPageComponents/CTA';
import { GuruInformation } from '../../LandingPageComponents/GuruInformation';

const LandingPage = () => {

    const navigate = useNavigate()
    const gradientStyle = {
        background: 'linear-gradient(to top, #FFD2C4, #FFEEE9, #FFFCFB)'
    }

    return <div style={{ flex: 1, ...gradientStyle, paddingBottom: 0 }}>

        <section id="header">
            <div id='sfg-logo-text'>
                <h3 style={{ color: '#FF6D3F', fontSize: '1.5rem' }}>Sri Family Guide</h3>
            </div>
            <div className='logo-container'>
                <img id='sfg-logo-image' src={familyImg} alt='img' />
            </div>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <button onClick={() => {
                    navigate("/mobilenumber");
                }} id='download'>Login</button>
            </div>

        </section>

        {/* Section 2 - Event */}
        <section id='event-information'>
            <EventInformation />
        </section>

        {/* Section 3 Hero */}
        <section id='hero'>
            <CTA
                title="Paduka Darshan Festival 2024"
                subtitle="26th - 27th March at the CIDCO Exhibition Centre, Vashi, Navi Mumbai"
                description="Under the Sri Family Guide initiative, the Sakal Media Group is pleased to announce a unique
                opportunity to witness and worship the 18 Padukas of great Saints. Padma Bhushan Sri M will offer
                guidance along with Dr Purushottam Rajimwale, who will perform the daily Agnihotra ceremony.
                A musical programme called ''Soor-Sandhya'' by Shankar Mahadevan and Hariharan will also
                occur. Would you like to become a Guru Sevak? Join us for a training session while with us!"
                subtext="Join us for a spiritual gathering and experience the blessings of the saints!"
                callToAction="Register for free now!"
                ctaButtonText="Register Now"
                ctaAction={() => {
                    navigate('/mobilenumber')
                }} />
        </section>

        {/* Section 4 - Family Picture  */}
        <section id='fam-pic'>
            <GuruInformation />
        </section>

        {/* Section 5 -Pillars */}
        <section id='pillars'>
            <Pillars />
        </section>

        {/* Section 6 Sakal Data */}
        <section id="sakal">
            <div style={{
                border: "1px solid #A7A7A7",
                padding: "20px 40px",
                paddingTop: 90,
                borderRadius: "10px",
                margin: "20px",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "space-between",
                // ...gradientStyle
            }}>
                <Row>
                    <Col md={5}>
                        <img src={SakalLogo} className='img-fluid' />
                        <img src={logoImage} className='img-fluid' />
                    </Col>
                    <Col md={7}>
                        <p style={{ fontWeight: '400' }}>Sakal Media Group has been driven by integrity and an unwavering commitment to delivering
                            unbiased journalism for almost a century. With a steadfast dedication to serving society’s best
                            interests, the group has established itself as an impact-driven media company, empowering
                            communities through content, initiatives, and events.
                        </p>
                        <p style={{ fontWeight: '400' }}>The Sri Family Guide Program empowers families to unlock their full potential, guiding them
                            towards a life filled with health, wealth, and purpose. It's a journey towards greater happiness and
                            prosperity, and we're here to help you every step of the way.</p>
                    </Col>
                </Row>
                <div>

                </div>
                <div style={{ maxWidth: "calc(60% - 15px)" }}>


                </div>
            </div>

        </section>

        <section id="material">
            <FeaturesCarousel />
        </section>

        <footer>
            {/* contact section */}
            <div className='mb-5 px-5'>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "25%" }}>
                    <img src={familyImg} style={{ width: '200px' }} />
                    <img className='mt-2' src={sriimg} alt='img' style={{ width: '150px' }} />
                </div>
                <div className="mt-4">
                    <p style={{ fontWeight: 500, fontSize: "25px", textAlign: "center", lineHeight: "30px", fontFamily: '"Exo 2", sans-serif' }}>To Join Sri Family Guide Contact Us Here</p>
                </div>

                <div className='d-flex justify-content-between align-items-center flex-wrap px-3'>
                    <div style={{ width: '150px' }}>
                        <p >
                            <span style={{ fontSize: '14px', color: '#1E1E1E', fontWeight: '400' }}>Call to Know More:</span>
                            <p className='m-0 p-0' ><a href="tel:+918956344471" style={{ textDecoration: 'none', color: '#2B2B2B', fontSize: '12px', fontWeight: '700' }}>+91 8888839082</a></p>
                        </p>
                    </div>

                    <div style={{}}>
                        <p >
                            <span style={{ fontSize: '14px', color: '#1E1E1E', fontWeight: '400' }}>Simaces Learning LLP:</span>
                            <p className='m-0 p-0' style={{ color: '#2B2B2B', fontSize: '12px', fontWeight: '700' }}>82/1A & 83/1A, Sakal Nagar, Gate no. 1, Baner Road, Anudh, Pune, Maharashtra(411007)</p>
                        </p>
                    </div>

                    <div>
                        <p>
                            <span style={{ fontSize: '14px', color: '#1E1E1E', fontWeight: '400' }}>Write Us: </span>
                            <p className='m-0 p-0' ><a href="mailto:info@srifamilyguide.com" style={{ textDecoration: 'none', color: '#2B2B2B', fontSize: '12px', fontWeight: '700' }}>info@srifamilyguide.com</a></p>
                        </p>
                    </div>
                </div>
            </div>

            {/* footer section */}
            <div style={{ display: "flex", backgroundColor: "#000000", justifyContent: "space-between", alignItems: "center", padding: "15px 10px" }}>
                <p className="p-0 m-0" style={{ fontWeight: 400, fontSize: "12px", fontFamily: '"Exo 2", sans-serif', color: '#fff',cursor:"pointer" }} onClick={() =>navigate("/terms")}>Terms and Conditions</p>
                <p className="p-0 m-0" style={{ fontWeight: 400, fontSize: "14px", fontFamily: '"Exo 2", sans-serif', color: '#fff' }} >&copy; Copyright Sakal Media Group &reg;</p>
            </div>
        </footer>

    </div>
}

export default LandingPage;
