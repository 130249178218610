import React, { useEffect, useMemo, useState } from 'react'
import { Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import Select from "react-select";
import { ApiCalling } from '../../../Services/Pool';
import { toast } from 'react-toastify'
import { showToastError, showToastSuccess, showToastWarning } from '../../../Utilities/ToastUtils';
import { useLocation, useNavigate } from 'react-router-dom';
import ReuseCode from './ReuseComponent/ReuseCode';
import headerImage from "../../../Assets/headerimage.png";
import familygLogo from "../../../Assets/familylogo.png";
import Back from "../../../Assets/back.png";
import useGetLabels from '../../CustomHookes/useGetLabels';
import * as Icon from 'react-bootstrap-icons';
import { useDetector } from '../UserAgent/useDetector';

const Register = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const { os } = useDetector()

    // state for language change
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("language") || "mr");

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
    };

    const [loader, setLoader]  = useState(false);
    const [fullName, setFullName] = useState("");
    const [gender, setGender] = useState("");
    const [dob, setDob] = useState();
    const [mobile, setMobile] = useState(location.state?.payload.mobile);
    const [profession, setProfession] = useState("");
    const [employmentStatus, setEmploymentStatus] = useState(null);
    const [password, setPassword] = useState("")

    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [newPasswordIcon, setNewPasswordIcon] = useState(true)
    const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(true)

    const genderData = [
        {
            label: selectedLanguage == 'en' ? "Select Gender" : selectedLanguage == 'mr' ? 'लिंग निवडा' : 'लिंग चुनें',
            value: "",
        },
        {
            label: selectedLanguage == 'en' ? "Female" : selectedLanguage == 'mr' ? 'स्त्री' : 'महिला',
            value: "Female",
        },
        {
            label: selectedLanguage == 'en' ? "Male" : selectedLanguage == 'mr' ? 'पुरुष' : 'पुरुष',
            value: "Male",
        },
        {
            label: selectedLanguage == 'en' ? "Other" : selectedLanguage == 'mr' ? 'इतर' : 'अन्य',
            value: "Other",
        },
    ]

    const employmentStatusData = [
        { value: null, label: selectedLanguage == 'en' ? "Select Employment Status" : selectedLanguage == 'mr' ? 'रोजगार स्थिती निवडा' : 'रोजगार स्थिति का चयन करें', },
        { value: "Self Employed", label: "Self Employed" },
        { value: "Salaried", label: "Salaried" },
        { value: "Business Owner", label: "Business Owner" },
        { value: "Other", label: "Other" },
        { value: "Home Maker", label: "Home Maker" },
        { value: "Social Worker", label: "Social Worker" }
    ]

    const educationData = [
        { value: null, label: selectedLanguage == 'en' ? "Select Education" : selectedLanguage == 'mr' ? 'शिक्षण निवडा' : 'शिक्षा का चयन करें', },
        { value: "SSC", label: "SSC" },
        { value: "HSC", label: "HSC" },
        { value: "Graduate", label: "Graduate" },
        { value: "Other", label: "Other" },
        { value: "Diploma", label: "Diploma" },
        { value: "Post Graduate", label: "Post Graduate" },
        { value: "Doctorate", label: "Doctorate" },
    ]

    const [education, setEducation] = useState(null)
    const [formValid, setFormValid] = useState(false);
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [passwordEmpty, setPasswordEmpty] = useState(false);
    const [spaceInPassword, setSpaceInPassword] = useState(false);



    // for call custom hook
    const { getAllLabels } = useGetLabels();

    let ensetProfileScreenLabels, hnsetProfileScreenLabels, mrsetProfileScreenLabels,
        ensetPasswordScreenLabels, hnsetPasswordScreenLabels, mrsetPasswordScreenLabels;

    if (getAllLabels && getAllLabels.length > 0) {
        const labels = getAllLabels[0][selectedLanguage?.toLowerCase()];
        if (labels) {
            ensetProfileScreenLabels = labels.setProfileScreen;
            hnsetProfileScreenLabels = labels.setProfileScreen;
            mrsetProfileScreenLabels = labels.setProfileScreen;
            ensetPasswordScreenLabels = labels.setPasswordScreen;
            hnsetPasswordScreenLabels = labels.setPasswordScreen;
            mrsetPasswordScreenLabels = labels.setPasswordScreen;
        }
    }






    // Error State
    const [fullNameErr, setFullNameErr] = useState(false)
    const [genderErr, setGenderErr] = useState(false)
    const [dobErr, setDobErr] = useState(false);
    const [passwordEmptyError, setPasswordEmptyError] = useState(false);
    const [passwordMismatchError, setPasswordMismatchError] = useState(false);



    const updateFormValidity = () => {
        setFormValid(
            !fullNameErr &&
            !genderErr &&
            !dobErr &&
            true
        );
    };



    const validate = () => {
        let validate = true;

        setFullNameErr(false)
        setGenderErr(false)
        setDobErr(false)

        if (fullName === "") { setFullNameErr(true); validate = false }
        if (gender === "" || gender === "Select Gender" || gender === null) { setGenderErr(true); validate = false };
        if (dob === undefined && dob ==="") { setDobErr(true); validate = false }
        // if (mobile === "" || mobile?.length !== 10) { setMobileErr(true); validate = false }

        return validate;
    }

    // for password validation
    const validatePassword = () => {
        let valid = true;

        setPasswordEmptyError(false);
        setPasswordMismatchError(false);

        if (!newPassword || !confirmPassword) {
            setPasswordEmptyError(true);
            valid = false;
        }

        if (newPassword !== confirmPassword) {
            setPasswordMismatchError(true);
            valid = false;
        }

        // Additional password validation logic (e.g., minimum length, no spaces)

        return valid;
    };



    const registerUser = async () => {
        if (validate()) {
            setLoader(true);
            const url = ApiCalling.base_url_v1;
            const constant = `/user/register`;
            const method = "POST";
            const reqBody = {
                name: fullName,
                gender: gender?.label,
                email: 'simaceslearning.llp@gmail.com',
                dob: dob,
                mobile: mobile,
                whatsAppNumber: mobile,
                profession: profession,
                employmentStatus: employmentStatus?.label,
                education: education?.label,
                address: '',
                village: '',
                district: '',
                taluka: '',
                pincode: '',
                profilePic: '',
                password: newPassword,
                device: os
            }
            const res = await fetch(url + constant, {
                method: method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(reqBody),
            });
            const response = await res.json();
            if (response?.message === "User registered Successfully") {
                showToastSuccess('User registered Successfully');
                await login();
            }
            else{
                showToastError("User is already registered.");
                setLoader(false);
            }
        }
    }

    const handleRegisterUser = () => {
        setFullNameErr(false);
        setGenderErr(false);
        setDobErr(false);
        setPasswordEmpty(false);
        setPasswordMismatch(false);

        if (!fullName) {
            setFullNameErr(true);
        }

        if (!gender || !gender.value) {
            setGenderErr(true);
        }

        if (!dob) {
            setDobErr(true);
        }

        if (!validatePassword()) {
            return;
        }

        updateFormValidity();

        if (formValid) {
            registerUser();
        }
        if (newPassword === '' || confirmPassword === '') {
            setPasswordEmpty(true);
        }

        // Check if password and confirm password match
        if (newPassword !== confirmPassword) {
            setPasswordMismatch(true);
        }
    };

    const handleFullNameChange = (e) => {
        setFullName(e.target.value);
        setFullNameErr(false);
    };

    const handleDobChange = (e) => {
        setDob(e.target.value);
        setDobErr(false);
    };

    const handleGenderChange = (option) => {
        setGender(option);
        setGenderErr(false);
    };

    const handleEaseBuzz = async()=>{
        console.log('Calling');
        const token = localStorage.getItem('token');
        console.log("Token from local", localStorage.getItem('token'));
        console.log("Token from prop", token);
        console.log("User from local", JSON.parse(localStorage.getItem('user')));
        try{
            const result = await fetch(`${ApiCalling.base_url_v1}${ApiCalling.paths.initiatePaymentLogin}`,
            {
                method: "POST",
                headers: { "Content-Type": "application/json", 
                            Authorization:`Bearer ${token}`},
                body: JSON.stringify({
                    plan:localStorage.getItem('Plan'),
                    productinfo:`Sri Family Guide ${localStorage.getItem('Plan')}`,
                    udf5:'',
                    udf6:'',
                    udf8:'',
                    udf9:'',
                    udf10:'',
                    furl:'https://stagingapi.sfg.foxberry.live/v1/pay/paymentResponse',
                    surl:'https://stagingapi.sfg.foxberry.live/v1/pay/paymentResponse'
                })
            });
            const response = await result.json();
            if(response?.message === "Already Purchased"){
                showToastSuccess('Already Purchased');
                setLoader(false);
            }
            else if(response?.status === 1){
                window.location.href = response?.data
                setLoader(false);
            }
            setLoader(false);
        }catch(Err){
            console.log("Err while calling Ease Buzz API", Err);
            showToastError("Payment failed, please visit again");
            setLoader(false);
        }
    }



    const login = async () => {
        const url = ApiCalling.base_url_v1
        const constant = `/user/login`
        const method = "POST"
        const reqBody = {
            "mobile": mobile,
            "password": newPassword,
        }
        const res = await fetch(url + constant, {
            method: method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(reqBody),
        });
        const response = await res.json();
        if (response?.message === "Login Successful") {
            localStorage.removeItem('popupShown');
            showToastSuccess("Login successfully");
            console.log("response", response);
            localStorage.setItem('token', response?.data?.token);
            localStorage.setItem("user", JSON.stringify(response?.data?.user));
            // navigate(`/event-details/${data.token}/${selectedLanguage}`, { state: { showPopup: true } })
            handleEaseBuzz(response?.data?.token);
            //TODO: Initiate payment based on requeted membership
            //TODO: Navigate to a page with route params - type of membership
            //TODO: Let the page initiate the payment
        }
        else{
            showToastError("Login Failed, Please try login");
            navigate('/mobilenumber');
            setLoader(false);
        }
    }

    React.useEffect(() => {
        validatePassword()
    }, [confirmPassword, newPassword])


    return (
        <>
            <div style={{ marginBottom: "20%" }}>
                <ReuseCode
                    headerImage={headerImage}
                    Back={Back}
                    familygLogo={familygLogo}
                    title={selectedLanguage === "mr" ? mrsetProfileScreenLabels?.title : selectedLanguage === "hn" ? hnsetProfileScreenLabels?.title : ensetProfileScreenLabels?.title}
                    onLanguageChange={handleLanguageChange}
                    endPoint="/authentication"
                /> 
                <Row className='m-3'>
                    <Col md={6}>
                        {fullNameErr ? <span className='errMsg'>* {selectedLanguage === "mr" ? "कृपया पूर्ण नाव प्रविष्ट करा" : selectedLanguage === "hn" ? "कृपया पूरा नाम दर्ज करें" : "Please Enter full Name"}</span> : null}
                        <Form.Group className="mb-3">
                            <Form.Label className='fw-bold'>{selectedLanguage === "mr" ? "पूर्ण नाव प्रविष्ट करा" : selectedLanguage === "hn" ? "पूरा नाम दर्ज करें" : "Full Name"} <span className='text-danger'>*</span></Form.Label>
                            <Form.Control size='lg' placeholder={selectedLanguage === "mr" ? mrsetProfileScreenLabels?.fullName : selectedLanguage === "hn" ? hnsetProfileScreenLabels?.fullName : ensetProfileScreenLabels?.fullName} className="custom-placeholder-color inputStyle"
                                value={fullName}
                                onChange={handleFullNameChange}
                            // onChange={(e) => setFullName(e.target.value)} 
                            />
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        {genderErr ? <span className='errMsg'>*{selectedLanguage === "mr" ? "कृपया लिंग निवडा" : selectedLanguage === "hn" ? "कृपया लिंग चुनें" : "Please Enter gender"}</span> : null}
                        <Form.Group className="mb-3">
                            <Form.Label className='fw-bold'>{selectedLanguage === "mr" ? "कृपया लिंग निवडा" : selectedLanguage === "hn" ? "लिंग चुनें" : "Select gender"} <span className='text-danger'>*</span></Form.Label>
                            <Select
                                placeholder={selectedLanguage === "mr" ? mrsetProfileScreenLabels?.gender : selectedLanguage === "hn" ? hnsetProfileScreenLabels?.gender : ensetProfileScreenLabels?.gender}
                                isMulti={false}
                                isSearchable={true}
                                value={gender}
                                options={genderData}
                                onChange={handleGenderChange}
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        borderColor: state.isFocused ? '#f4a28b' : '#f4a28b',
                                        padding: "15px",
                                        borderRadius: "8px",
                                        '&:hover': {
                                            borderColor: '#f4a28b',
                                        },
                                    }),
                                }}
                            />
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        {dobErr ? <span className='errMsg'>*{selectedLanguage === "mr" ? "कृपया जन्मतारीख निवडा" : selectedLanguage === "hn" ? "कृपया जन्मतिथि चुनें" : "Please select date of birth"}</span> : null}
                        <Form.Group className="mb-3">
                            <Form.Label className='fw-bold'>{selectedLanguage === "mr" ? "जन्मतारीख निवडा" : selectedLanguage === "hn" ? "जन्मतिथि चुनें" : "Select Date of Birth"} <span className='text-danger'>*</span></Form.Label>
                             <Form.Control size='lg' type='date' placeholder={selectedLanguage === "mr" ? mrsetProfileScreenLabels?.dob : selectedLanguage === "hn" ? hnsetProfileScreenLabels?.dob : ensetProfileScreenLabels?.dob} className="custom-placeholder-color inputStyle"
                                value={dob}
                                // onChange={(e) => setDob(e.target.value)} max={new Date().toISOString().split('T')[0]} 
                                onChange={handleDobChange}
                                max={new Date().toISOString().split('T')[0]}
                            />
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        {/* {mobileErr ? <span className='errMsg'>*Please enter mobile number</span> : null} */}
                        <Form.Group className="mb-3">
                            <Form.Label className='fw-bold'>{selectedLanguage === "mr" ? "मोबाइल प्रविष्ट करा" : selectedLanguage === "hn" ? "मोबाइल दर्ज करें" : "Enter Mobile"} <span className='text-danger'>*</span></Form.Label>
                            <Form.Control size='lg' placeholder={selectedLanguage === "mr" ? mrsetProfileScreenLabels?.mob : selectedLanguage === "hn" ? hnsetProfileScreenLabels?.mob : ensetProfileScreenLabels?.mob} className="custom-placeholder-color inputStyle"
                                value={mobile} />
                        </Form.Group>
                    </Col>

                

                    <Form className='passwordForm my-3'>
                        <Col md={6}>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    type={newPasswordIcon ? 'password' : 'text'}
                                    placeholder={selectedLanguage === "mr" ? mrsetPasswordScreenLabels?.newPasswordPlaceholder : selectedLanguage === "hn" ? hnsetPasswordScreenLabels?.newPasswordPlaceholder : ensetPasswordScreenLabels?.newPasswordPlaceholder}
                                    value={newPassword}
                                    onChange={(e) => { setNewPassword(e.target.value.replace(/[\s]/, '')); }}
                                    style={{ border: '1px solid #FFD7CD' }}
                                />
                                {newPasswordIcon ? (
                                    <Icon.EyeSlash
                                        className='passwordIcon'
                                        onClick={() => setNewPasswordIcon(!newPasswordIcon)}
                                        size={20}
                                        color={'#B3B3B3'}
                                    />
                                ) : (
                                    <Icon.Eye
                                        className='passwordIcon'
                                        onClick={() => setNewPasswordIcon(!newPasswordIcon)}
                                        size={20}
                                        color={'#B3B3B3'}
                                    />
                                )}
                            </InputGroup>
                        </Col>

                        <Col md={6}>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder={selectedLanguage === "mr" ? mrsetPasswordScreenLabels?.confirmPasswordPlaceholder : selectedLanguage === "hn" ? hnsetPasswordScreenLabels?.confirmPasswordPlaceholder : ensetPasswordScreenLabels?.confirmPasswordPlaceholder}
                                    type={confirmPasswordIcon ? 'password' : 'text'}
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value.replace(/[\s]/, ''));
                                         // Call validatePassword function on every change
                                    }}
                                    style={{ border: '1px solid #FFD7CD' }}
                                />
                                {confirmPasswordIcon ? (
                                    <Icon.EyeSlash
                                        className='passwordIcon'
                                        onClick={() => setConfirmPasswordIcon(!confirmPasswordIcon)}
                                        size={20}
                                        color={'#B3B3B3'}
                                    />) : (
                                    <Icon.Eye
                                        className='passwordIcon'
                                        onClick={() => setConfirmPasswordIcon(!confirmPasswordIcon)}
                                        size={20}
                                        color={'#B3B3B3'}
                                    />
                                )}
                            </InputGroup>
                            {/* Add error message display for password mismatch */}
                            {passwordMismatchError && (
                                <span className='errMsg'>{selectedLanguage === "mr" ? "नवीन गुप्तपासवर्ड आणि पुष्टीकरण गुप्तपासवर्ड एकसारखे नाहीत" : selectedLanguage === "hn" ? "नया पासवर्ड और पासवर्ड की पुष्टि मेल नहीं खाते" : "New password and confirm password do not match"}</span>
                            )}
                            {/* Add error message display for empty passwords */}
                            {passwordEmptyError && (
                                <span className='errMsg'>{selectedLanguage === "mr" ? "नवीन गुप्तपासवर्ड आणि पुष्टीकरण गुप्तपासवर्ड खाली असू नये" : selectedLanguage === "hn" ? "नया पासवर्ड और पासवर्ड की पुष्टि खाली नहीं हो सकते" : "New password and confirm password cannot be empty"}</span>
                            )}
                        </Col>
                    </Form>
                </Row>

                <div style={{ display: "flex", justifyContent: "space-around", margin: "0px 15px 0px 15px" }}>
                    <Button style={{
                        borderRadius: "20px", padding: "10px", width: "35%", backgroundColor: "transparent", color: "#FD6E47",
                        border: "1px solid #FD6E47"
                    }}
                        onClick={() => navigate("/mobilenumber")}>{selectedLanguage === "mr" ? "मागे" : selectedLanguage === "hn" ? "पीछे" : "Back"}</Button>

                    {
                        loader  ? <center><Spinner variant="primary"/></center> :
                        <Button
                            style={{ borderRadius: "20px", padding: "10px", width: "35%", backgroundColor: "#FD6E47", color: "white", border: "none" }}
                            onClick={handleRegisterUser}>
                            {selectedLanguage === "mr" ? mrsetProfileScreenLabels?.submitBtn : selectedLanguage === "hn" ? hnsetProfileScreenLabels?.submitBtn : ensetProfileScreenLabels?.submitBtn}
                        </Button>
                    }    
                   
                </div>
            </div>
        </>
    )
}

export default Register
