import "./event.css";
import { useState, useEffect } from 'react';

import banner_16_9 from "./../../Assets/landing/gurubanner_16_9.png";
import banner_9_16 from "./../../Assets/landing/gurubanner_9_16.png";
import banner_4_3 from ".././../Assets/landing/gurubanner_4_3.png";

export const GuruInformation = () => {
  const [deviceType, setDeviceType] = useState('desktop'); // Initial state

  useEffect(() => {
    const checkDeviceType = () => {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        if (window.innerWidth < 1024) { // Adjust this threshold as needed
          setDeviceType('mobile');
        } else {
          setDeviceType('tablet');
        }
      } else {
        setDeviceType('desktop');
      }
    };

    checkDeviceType();
    window.addEventListener('resize', checkDeviceType);

    return () => window.removeEventListener('resize', checkDeviceType);
  }, []);

  return (
    <div>
      <img
        style={{ width: '100%' }}
        src={`${
          deviceType === 'mobile'
            ? banner_9_16
            : deviceType === 'tablet'
            ? banner_4_3
            : banner_16_9
        }`}
        alt=""
        className="src"
      />
    </div>
  );
};

