import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import dateImg from "../../../Assets/Date.png"
import locationImg from "../../../Assets/Location.png"
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import call from "../../../Assets/Group 33664.png";
import { ApiCalling } from '../../../Services/Pool';
import { showToastSuccess, showToastWarning } from '../../../Utilities/ToastUtils';
import useGetLabels from '../../CustomHookes/useGetLabels';
import SomethingWrong from '../PageNotFound/SomethingWrong';
import tick from "../../../Assets/tick.png"
import PageDistrict from '../PageNotFound/PageDistrict';
import FamilyGrp from "../../../Assets/familygrp.svg"
import { useDetector } from '../UserAgent/useDetector';

const EventDetails = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const { os } = useDetector()

  const { token, lang } = useParams()

  const [eventData, setEventData] = useState([])
  const [registerData, setRegiaterData] = useState([])
  const [helpnumber, setHelpNumber] = useState([])
  const [slotData, setSlotData] = useState([])
  const [userdetails, setUserDetails] = useState([])
  const [loader, setLoader] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [apiError, setApiError] = useState(false)

  // for selected slot
  const [selectedSlot, setSelectedSlot] = useState("");
  const [filteredSlots, setFilteredSlots] = useState([]);

  // for selected date
  const [selectedDate, setSelectedDate] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isSlotSelected, setIsSlotSelected] = useState(false);
  const [showDistrictWarning, setSDW] = useState(false)


  // for additional family member
  const [additionalFamilyMembers, setAdditionalFamilyMembers] = useState(0);
  const familyMemberOptions = [0, 1, 2, 3, 4, 5, 6];

  const [showPopup, setShowPopup] = useState(localStorage.getItem('popupShown') === 'close' ? false : true);

  const bookYourSlotRef = useRef(null);

  const scrollToBookYourSlot = () => {
    if (bookYourSlotRef.current) {
      bookYourSlotRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleContinueBooking = () => {
    localStorage.setItem('popupShown', 'close');
    localStorage.setItem('bookingData', JSON.stringify({
      eventData: eventData,
      selectedSlot: selectedSlot,
      additionalFamilyMembers: additionalFamilyMembers
    }));
    setShowPopup(false);

    scrollToBookYourSlot();
  };

  const handleFamilyMemberChange = (event) => {
    setAdditionalFamilyMembers(parseInt(event.target.value));
  };

  useEffect(() => {
    const isPopupShown = localStorage.getItem('popupShown');
    if (location.state && location.state.showPopup && !isPopupShown) {
      setShowPopup(true);
      localStorage.setItem('popupShown', true);
    }
    getEventData()
  }, [token])

  // for call custom hook
  const { getAllLabels, setApiLabelError, apiLabelError } = useGetLabels();

  let eneventLabels, hneventLabels, mreventLabels;

  if (getAllLabels !== undefined && getAllLabels !== null && getAllLabels.length > 0 && getAllLabels && getAllLabels.length > 0) {
    const { en, hn, mr } = getAllLabels[0];

    if (en && hn && mr) {
      eneventLabels = en.eventLabels;
      hneventLabels = hn.eventLabels;
      mreventLabels = mr.eventLabels;
    }
  }


  // getevent details
  const getEventData = async () => {
    setLoader(true)
    try {
      const response = await fetch(
        `${ApiCalling.base_url_v1}${ApiCalling.paths.getEventForUser}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json", "authorization": token },
        }
      );
      const responseData = await response.json();
      if (responseData?.status == 404) {
        setSDW(true)
      } else {
        setEventData(responseData?.eventData)
        setRegiaterData(responseData?.registerData)
        setSlotData(responseData?.slots)
        setHelpNumber(responseData?.helpNumbers)
        setUserDetails(responseData?.userDetails)
        setLoader(false)
      }
    }

    catch (error) {
      setLoader(false)
      setApiError(true)
      setApiLabelError(true)
    }
  }

  // book event
  const handleBookEvent = async (eventID, selectedSlot) => {
    setLoader(true)
    try {
      const payload = {
        eventId: eventID,
        slot: [
          {
            "_id": selectedSlot?._id,
            "batch": selectedSlot?.batch,
            "date": selectedSlot?.date,
            "hallNumber": selectedSlot?.hallNumber,
            "slots": selectedSlot?.slots,
            "bookingLimit": selectedSlot?.bookingLimit,
            "booked": selectedSlot?.booked
          }
        ],
        familyMembersCount: additionalFamilyMembers,
        lang: lang,
        device: os
      }

      const response = await fetch(

        `${ApiCalling.base_url_v1}${ApiCalling.paths.bookEvent}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json", "authorization": token },
          body: JSON.stringify(payload)
        }
      );
      const responseData = await response.json();
      setLoader(false)
      if (responseData?.message === 'Already Registered') {
        setLoader(false)
        showToastWarning("Event Already Booked.")
        setShowAdd(false)
      } else if (responseData?.message === 'Register Successfully') {
        setLoader(false)
        showToastSuccess("Event Booked Successfully !")
        setShowAdd(false)
        getEventData()
        setTimeout(() => {
          var qrCodeContainer = document.getElementById('qrCodeContainer');
          if (qrCodeContainer) {
            qrCodeContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 1000);
        // localStorage.setItem('popupShown', true);
      }
    } catch (error) {
      setApiError(true)
      console.log("error")
    }
  }

  // for modal
  const handleModal = () => {

    if (isSlotSelected && selectedDate) {
      // navigate("/eventConfirm", { state: { eventID: eventID, data: selectedSlot, lang: lang, userdetails: userdetails, token: token } })
      setShowAdd(!showAdd);
    } else {
      showToastWarning("Please select preferred Date and Slot.");
    }
  };

  const handleSlotClick = (ele) => {
    if (registerData?.status !== "Confirmed") {
      if (selectedSlot === ele) {
        setSelectedSlot(null);
        setIsSlotSelected(false);
        setIsButtonDisabled(true);
      } else {
        setSelectedSlot(ele);
        setIsSlotSelected(true);
        setIsButtonDisabled(false);
      }
    }
  };

  // for date click
  const handleDateClick = (date) => {
    if (selectedDate === date) {
      setSelectedDate(null);
      setSelectedSlot(null);
      setIsButtonDisabled(true);
      setFilteredSlots([]);
    } else {
      setSelectedDate(date);
      setSelectedSlot(null);
      setIsButtonDisabled(true);
      // for filter sate wise slot
      const filteredSlots = slotData.filter(slot => slot.date === date);
      setFilteredSlots(filteredSlots);
    }
  };


  if (showDistrictWarning) {
    return <PageDistrict />
  }

  const handleClose = () => {
    setShowPopup(false);
    handleContinueBooking()
  };


  return (
    <>
      {showPopup && (
        <Modal show={showPopup} onHide={handleClose} centered className='p-4' backdrop="static">
          <Card className="modal-card">
            <Modal.Header style={{ width: '100%' }}>
              <Modal.Title style={{ width: '100%' }}><h1 style={{ textAlign: 'start', width: '100%' }}>Registrations Open!</h1></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{ fontWeight: '400', fontSize: '15px' }}>Join us for a spiritual gathering and experience the blessings of the saints!</p>
            </Modal.Body>
            <Modal.Footer>
              <button className="primaryBtn" onClick={handleContinueBooking}>
                Continue Booking
              </button>
            </Modal.Footer>
          </Card>
        </Modal>
      )}
      <div className='mt-3'>
        {
          loader ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
              <Spinner size='lg' />
            </div>
            :
            apiError || apiLabelError ?
              <SomethingWrong /> :
              <>
                <div className='mainContainer' style={{ overflowX: 'scroll', height: '80vh' }}>
                  {/* {
                    registerData?.qrLink &&
                    <>
                      <div style={{ display: "flex", justifyContent: "end", marginRight: "5px" }}>
                        <button style={{ backgroundColor: "#FD6E47", color: "white", border: "none", padding: "5px 10px", borderRadius: "10px" }}
                          onClick={() => navigate('/scanner', { state: { scanner: registerData?.qrLink, uuid: registerData?.UUId, token: token, lang: lang } })}>
                          <Icon.QrCodeScan className='me-2' />View QR</button>
                      </div>
                    </>
                  } */}
                  <div className='imageContainer'>
                    <img src={eventData?.image} alt='image' style={{ width: "100%", borderRadius: "20px", marginTop: "10px" }} />
                  </div>

                  <div className='eventContainer' >
                    <p style={{ fontSize: "18px", fontWeight: 400, lineHeight: "28.8px" }}>
                      {lang === "mr" ? eventData?.mar?.eventName : lang === "hn" ? eventData?.hn?.eventName : eventData?.en?.eventName}
                    </p>
                    <div style={{ display: "flex", alignItems: "center", marginTop: "5%" }}>
                      <img src={dateImg} className='contentImg' />
                      <div>
                        <h6 style={{ fontSize: "14px", fontWeight: 400, margin: 0 }}>
                          {lang === "mr" ? eventData?.mar?.day1 : lang === "hn" ? eventData?.hn?.day1 : eventData?.en?.day1} {lang === "mr" ? eventData?.mar?.and : lang === "hn" ? eventData?.hn?.and : eventData?.en?.and} {lang === "mr" ? eventData?.mar?.day2 : lang === "hn" ? eventData?.hn?.day2 : eventData?.en?.day2}
                        </h6>
                      </div>
                    </div>

                    <div style={{ display: "flex", marginTop: "20px" }}>
                      <img src={locationImg} className='contentImg' />
                      <div>
                        <h6 style={{ fontSize: "14px", fontWeight: 400, lineHeight: "19.07px" }}>{lang === "mr" ? eventData?.mar?.address : lang === "hn" ? eventData?.hn?.address : eventData?.en?.address}</h6>
                        <a href={eventData?.locationUrl} target="_blank" rel="noopener noreferrer">
                          {lang === "mr" ? eventData?.mar?.takeMeLocation : lang === "hn" ? eventData?.hn?.takeMeLocation : eventData?.en?.takeMeLocation}
                        </a>
                      </div>
                    </div>

                    <div className="mt-5" ref={bookYourSlotRef}>
                      <h1 className="slotHeading" >
                        {registerData?.status === "Confirmed"
                          ? lang === "mr"
                            ? mreventLabels?.slotisbooked
                            : lang === "hn"
                              ? hneventLabels?.slotisbooked
                              : eneventLabels?.slotisbooked
                          : lang === "mr"
                            ? mreventLabels?.bookyourSlot
                            : lang === "hn"
                              ? hneventLabels?.bookyourSlot
                              : eneventLabels?.bookyourSlot}
                      </h1>

                      {/* for slot booking */}
                      <div className="mt-3">

                        {registerData?.status !== "Confirmed" &&
                          <div className='d-flex justify-content-between p-2'>

                            <>
                              <div
                                className={`slotBtn ${selectedDate === "26th March" ? 'selected' : ''}`}
                                onClick={() => handleDateClick("26th March")}
                              >
                                <Icon.Calendar2Event size={25} /> {lang === "mr" ? eventData?.mar?.day1 : lang === "hn" ? eventData?.hn?.day1 : eventData?.en?.day1}
                              </div>
                              <div
                                className={`mx-2 slotBtn ${selectedDate === "27th March" ? 'selected' : ''}`}
                                onClick={() => handleDateClick("27th March")}
                              >
                                <Icon.Calendar2Event size={25} /> {lang === "mr" ? eventData?.mar?.day2 : lang === "hn" ? eventData?.hn?.day2 : eventData?.en?.day2}
                              </div>
                            </>
                          </div>
                        }

                        {selectedDate && registerData?.status !== "Confirmed" ? (
                          <div className='d-flex flex-wrap mt-3'>
                            {filteredSlots
                              .sort((a, b) => new Date('1990/01/01 ' + a.slots) - new Date('1990/01/01 ' + b.slots))
                              .map((ele, index) => {
                                return (
                                  <div
                                    key={index}
                                    onClick={() => handleSlotClick(ele)}
                                    className={`mx-1 slotCard ${selectedSlot === ele ? 'selected' : ''}`}
                                    // disabled={!ele.isSlotAvailable}
                                    disabled={ele.booked === ele.bookingLimit}
                                    style={{ width: 'calc(49.33% - 20px)', margin: '5px auto' }}
                                  >
                                    <div className='d-flex justify-content-between align-items-center'>
                                      {registerData?.status === "Confirmed" && <img src={tick} />}
                                    </div>
                                    <div className='mt-2'>
                                      <h6 className="m-0 p-0" style={{ fontSize: '12px', color: '#353534' }}>{ele?.slots}</h6>
                                      <h6 className="m-0 p-0" style={{ fontSize: '10px', color: '#353534' }}>{ele?.date}</h6>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          registerData?.status === "Confirmed" && slotData?.map((ele) => {
                            return (
                              <div className='d-flex flex-wrap' key={ele._id}>
                                <div className={`mx-1 p-1 slotCard1 ${selectedSlot ? 'selected' : ''}`} style={{ width: 'calc(49.33% - 20px)', margin: '5px auto', border: '1px solid #2F9177', borderRadius: '10px' }}>
                                  <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                      <h6 className="mt-2 p-0" style={{ fontSize: '14px' }}>{ele?.date}</h6>
                                      <h6 className="m-0 p-0" style={{ fontSize: '12px' }}>{ele?.slots}</h6>
                                    </div>
                                    <img src={tick} style={{ marginLeft: '5px' }} />
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        )}
                      </div>
                    </div>
                  </div>


                  {/* for add family member */}
                  <div style={{ marginTop: "15px", width: "300px" }}>
                    <h1 style={{ fontSize: "20px", fontWeight: 600, lineHeight: "34px" }}>
                      {lang === "mr" ? mreventLabels?.familyMembers : lang === "hn" ? hneventLabels?.familyMembers : eneventLabels?.familyMembers}
                    </h1>
                    {registerData?.status !== "Confirmed" && (
                      <Form.Select
                        value={additionalFamilyMembers}
                        onChange={handleFamilyMemberChange}
                        style={{ maxWidth: "200px", overflow: "auto", marginLeft: '4px', border: '1px solid black' }}
                      >
                        {familyMemberOptions.map((option, index) => (
                          <option key={index} value={option}>{option}</option>
                        ))}
                      </Form.Select>
                    )}
                    {registerData?.status === "Confirmed" && (
                      <div style={{
                        backgroundColor: '#EBF3FC',
                        color: 'black',
                        padding: '10px',
                        borderRadius: '20px',
                        display: 'inline-block',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                      }}>
                        <span style={{ fontSize: '18px', marginRight: '5px' }}> <img src={FamilyGrp} alt="" width={25} /></span>
                        <span style={{ fontSize: '16px' }}>{registerData?.familyMembersCount}</span>
                      </div>
                    )}
                  </div>

                  <div style={{ backgroundColor: "#EBF3FC", padding: "15px", borderRadius: "10px", marginTop: "15px" }}>
                    <h6 style={{ fontSize: "16px", fontWeight: 600, lineHeight: "34px" }}>
                      {lang === "mr" ? mreventLabels?.aboutEvent : lang === "hn" ? hneventLabels?.aboutEvent : eneventLabels?.aboutEvent}
                    </h6>


                    <div>
                      <p style={{ fontSize: "14px", fontWeight: 400, lineHeight: "22px" }}>
                        {lang === "mr" ? eventData?.mar?.description : lang === "hn" ? eventData?.hn?.description : eventData?.en?.description}
                      </p>
                    </div>
                  </div>

                  <div>
                    <h6 style={{ margin: "15px 0px 15px 0px", fontSize: "16px" }}>
                      {lang === "mr" ? mreventLabels?.anyHelp : lang === "hn" ? hneventLabels?.anyHelp : eneventLabels?.anyHelp}
                    </h6>

                    <div style={{}}>
                      {
                        helpnumber !== undefined &&
                        helpnumber !== null &&
                        helpnumber.length > 0 &&
                        helpnumber?.map((data, index) => {
                          return (
                            <>
                              <div style={{ display: "flex", marginTop: "10px", marginBottom: '20px' }}>
                                <img src={call} className='contentImg' />
                                <div>
                                  <h6 style={{ fontSize: "16px", fontWeight: 600 }}>{data.name}</h6>
                                  <h6 style={{ fontSize: "14px", fontWeight: 400 }}><a href={`tel:+91${data.phoneNumber}`}>{data.phoneNumber}</a></h6>
                                </div>
                              </div>
                            </>
                          )
                        })
                      }
                    </div>
                  </div>

                </div>


                <div className='d-flex justify-content-center my-4'
                  style={{
                    width: "90%", display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "40px",
                    position: "fixed", bottom: 0, left: "20px", zIndex: '0'
                  }}>
                  {/* {
                    registerData?.qrLink &&
                    <>
                      <div style={{ display: "flex", justifyContent: "end", marginRight: "5px" }}>
                        <button style={{ backgroundColor: "#FD6E47", color: "white", border: "none", padding: "5px 10px", borderRadius: "10px" }}
                          onClick={() => navigate('/scanner', { state: { scanner: registerData?.qrLink, uuid: registerData?.UUId, token: token, lang: lang } })}>
                          <Icon.QrCodeScan className='me-2' />View QR</button>
                      </div>
                    </>
                  } */}
                  {registerData?.status === "Confirmed" && registerData?.qrLink && (
                    <button
                      className="primaryBtn"
                      style={{ marginRight: "10px", padding: '0px' }}
                      onClick={() => navigate('/scanner', { state: { scanner: registerData?.qrLink, uuid: registerData?.UUId, token: token, lang: lang, familyMembers: registerData?.familyMembersCount, participateName: userdetails } })}
                    >
                      <Icon.QrCodeScan className='me-2' />
                      {lang === "mr" ? mreventLabels?.rqcode : lang === "hn" ? hneventLabels?.rqcode : eneventLabels?.rqcode}
                    </button>
                  )}

                  <button
                    className={registerData?.status === "Confirmed" || isButtonDisabled ? "disabledBtn" : "primaryBtn"}
                    disabled={registerData?.status === "Confirmed" ? true : false}
                    onClick={() => { handleModal() }}
                  >
                    {(registerData?.status === "Confirmed")
                      ? lang === "mr"
                        ? mreventLabels?.EventBooked
                        : lang === "hn"
                          ? hneventLabels?.EventBooked
                          : eneventLabels?.EventBooked

                      : lang === "mr"
                        ? mreventLabels?.BookEvent
                        : lang === "hn"
                          ? hneventLabels?.BookEvent
                          : eneventLabels?.BookEvent}
                  </button>
                </div>
              </>
        }
      </div >

      {/* Book event model */}
      <Modal size='sm' show={showAdd} backdrop='static' keyboard={false} centered onHide={() => { handleModal() }}>
        <Modal.Header closeButton>
          <Modal.Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <h5>
                {lang === "mr" ? mreventLabels?.confirmation : lang === "hn" ? hneventLabels?.confirmation : eneventLabels?.confirmation}
              </h5>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            loader ?
              <><Spinner size='sm' /></> :
              apiError ?
                "Something went wrong" :
                <>
                  <div style={{ width: "100%", display: "flex", justifyContent: "space-around" }}>
                    <Button style={{
                      backgroundColor: "transparent", color: "#FD6E47", border: "1px solid #FD6E47", borderRadius: "20px",
                      padding: "10px 25px"
                    }} disabled={loader} onClick={() => handleModal()}>
                      {lang === "mr" ? mreventLabels?.cancel
                        : lang === "hn" ? hneventLabels?.cancel
                          : eneventLabels?.cancel}
                    </Button>
                    <Button style={{
                      backgroundColor: "#FD6E47", color: "white", border: "1px solid #FD6E47", borderRadius: "20px",
                      padding: "10px 25px"
                    }} disabled={loader} onClick={() => handleBookEvent(eventData?._id, selectedSlot)}>
                      {lang === "mr" ? mreventLabels?.confirm
                        : lang === "hn" ? hneventLabels?.confirm
                          : eneventLabels?.confirm
                      }
                    </Button>
                  </div>
                </>
          }

        </Modal.Footer>
      </Modal >
    </>
  )
}

export default EventDetails
