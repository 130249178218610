
import Book from "../../../../Assets/book.png";
import swasthyam from "../../../../Assets/swasthyam.png";
import backgroundShade from "../../../../Assets/backgroundshade.svg"
import backImg from "../../../../Assets/backimg.svg"
import Aagni from "../../../../Assets/Isolation_Mode (1).png";
import { useRef, useState, useEffect } from "react";
import "./features.css";
import ReactOwlCarousel from "react-owl-carousel";

export const FeaturesCarousel = () => {

    const sliderRef = useRef(null);
    const features = [{
        feature: 'Darshan',
        description: `It is a once-in-a-lifetime
        opportunity to receive
        Darshan from 18 Gurus
        in two days, all in one
        place.`,
        additional: `Bring your entire family
        or group of friends and
        experience a blessed
        time together.`,
    },
    {
        feature: 'Guru Seva Training',
        description: `Looking to follow in the
        footsteps of great Gurus
        and give back? Join our
        Guru Seva Training for
        two days of selfless
        service.`,
        additional: ``,
    },
    {
        feature: 'Entertainment',
        description: `We invite you to join us
        for an enjoyable time
        filled with timeless
        music, lectures,
        meditation, and joint
        Agnihotra sessions.`,
        additional: ``,
    },
    ]

    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
      // Check for mobile device on component mount
      const checkIfMobile = () => {
        // You might want a more robust check depending on your needs.
        setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)); 
      }
  
      checkIfMobile();
  
      // Add listener for screen resize (in case user switches orientation)
      window.addEventListener('resize', checkIfMobile);
  
      // Cleanup function for removing the listener
      return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    const handleSlideClick = () => {
        sliderRef.current.slickNext();
    };

    return <div>


        <ReactOwlCarousel loop responsive={{
            300: {
                items: 1,
                margin: 0,
                stagePadding: 10
            },
            600: {
                items: 3
            }
        }}>
            {features.map((feature, index) => (<div key={`carousel-item-${index}`} className="feature-item" style={{ minHeight: isMobile ? 200 : 400 }}>
                <div onClick={handleSlideClick} >
                    <h4 className="m-0 p-0" style={{ fontWeight: 700, fontSize: "20px", fontFamily: '"Exo 2", sans-serif' }}>{feature.feature}</h4>
                    <p className="m-0 p-0 mt-2" style={{ fontWeight: 400, fontSize: "14px" }}>{feature.description} </p>
                    <p className="m-0 p-0 mt-2" style={{ fontWeight: 400, fontSize: "14px" }}>{feature.additional} </p>
                </div>
            </div>))}
        </ReactOwlCarousel>

    </div>
}