import React, { useEffect, useState } from 'react'
import { ApiCalling } from '../../Services/Pool';

const useGetLabels = () => {
  const [getAllLabels, setGetAllLabels] = useState()
  const [apiLabelError, setApiLabelError] = useState(false)

  useEffect(() => {
    getLabelData()
  }, [])

  const getLabelData = async () => {
    try {
      const response = await fetch('https://api.sfg.foxberry.live/v1/labels/getAppLabels',
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const responseData = await response.json();
      setGetAllLabels(responseData)
    } catch (error) {
      setApiLabelError(true)
      console.log(error);
    }

  }

  return { getAllLabels, setApiLabelError, apiLabelError }
}

export default useGetLabels
