import React from 'react';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';

const PageDistrict = () => {
    return (
        <div className='something-wrapper' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='icon-wrapper' style={{ color: 'orange', marginRight: '10px' }}>
                <ExclamationTriangleFill className='icon' size={25} style={{ color: 'orange' }} />
            </div>
            <div className='text-wrapper'>
                <p className='error-text' style={{ color: 'orange' }}>Please update your district and Taluka in your profile page</p>
            </div>
        </div>
    );
};

export default PageDistrict;
