
import React, { useMemo, useState } from "react";
import { pillarsData } from "./pillars-data";

export const usePillars = () => {

    const [isSelected, setSelected] = useState(1);

    const pillars = React.useMemo(() => {
        return reorderArray(pillarsData, isSelected)

    }, [isSelected])


    const selectPillar = React.useCallback((selectedPillar) => {
        setSelected(pillarsData.findIndex(x => x.src === selectedPillar.src))
    }, [pillars])

    return {
        pillars,
        isSelected,
        selectPillar
    }
}

function reorderArray(arr = [], index) {
    if (index >= 0 && index < arr.length) {

        let srim, tambe, purushottam = null;
        srim = pillarsData[0]
        tambe = pillarsData[1]
        purushottam = pillarsData[2]

        switch (index) {
            case 0:
                //selection is srim
                return [tambe, srim, purushottam];
            case 1:
                //selection is tambe
                return [srim, tambe, purushottam];
            case 2:
                //selection is puroshottam
                return [srim, purushottam, tambe];
            default:
                //selection is tambe
                return [srim, tambe, purushottam];
        }

    } else {
        console.error("Invalid index. Please provide an index within the array bounds.");
        return arr;
    }
}
