import React, { useEffect, useState } from 'react';
import {useParams, useNavigate} from 'react-router-dom';

const ManagePlan = () => {

    const {plan} = useParams();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    console.log("plans", plan);

    useEffect(()=>{
        localStorage.setItem("Plan", plan);
        setLoader(true);
        setTimeout(()=>{
            navigate('/mobilenumber');
        },[2000]);
    },[])

    return (
        <div className='ManagePlan p-5'>
            <h1>{plan}</h1>
            { loader && <h2>Loading...</h2>}
        </div>
    )
}

export default ManagePlan;