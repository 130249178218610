export const CTA = ({ title, subtitle, description, subtext, callToAction, ctaButtonText, ctaAction }) => {
    return <>
        <div className='mt-5' style={{ textAlign: 'center' }}>
            <h1 className='m-0 p-0'>{title}</h1>
        </div>

        <div className='section-1-desc my-5'>
            <h6 className='m-0 p-0'>{subtitle}</h6>
            <p className='mt-5 px-md-5' style={{ textAlign: 'center' }}>{description}</p>
            <p className='mt-5 px-md-5' style={{ textAlign: 'center' }}>{subtext}</p>
            <p className='mt-5 px-md-5' style={{ textAlign: 'center' }}>{callToAction}</p>
        </div>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <button className="primaryBtn" onClick={ctaAction} style={{ maxWidth: '300px' }}>
                {ctaButtonText}
            </button>
        </div>


    </>
}