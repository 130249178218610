import "./event.css";
import { useState, useEffect } from 'react'; // Import React hooks

import banner_16_9 from "../../Assets/landing/landing_16_9.jpg";
import banner_9_16 from "./../../Assets/landing/landing_9_16.jpg";


export const EventInformation = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    }

    checkIfMobile();

    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  return (
    <div>
      <img
        style={{ width: '100%' }}
        src={`${isMobile ? banner_9_16 : banner_16_9}`}
        alt=""
        className="src"
      />
    </div>
  );
};
