import React, { useEffect, useRef, useState } from 'react'
import headerImage from "../../../Assets/headerimage.png"
import familygLogo from "../../../Assets/familylogo.png"
import Back from "../../../Assets/back.png"
import { Form, Spinner } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import ReuseCode from './ReuseComponent/ReuseCode'
import { ApiCalling } from '../../../Services/Pool'
import { showToastError, showToastSuccess } from '../../../Utilities/ToastUtils'
import { SendOtp } from '../../CustomHookes/SendOtp'
import useGetLabels from '../../CustomHookes/useGetLabels'


const Authentication = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // set mobile number for uselocation
    console.log("location", location?.state);
    const reSendMobileNo = location?.state;


    const [loader, setLoader] = useState(false)
    const [timer, setTimer] = useState(60)
    const [resendEnabled, setResendEnabled] = useState(true);
    const [user, setUser] = useState({});
    console.log("user", user)

    // usestate for enter digit
    const [otpDigits, setOtpDigits] = useState(['', '', '', '', '', '']);

    // create ref to store reference
    const otpInputRefs = useRef(Array.from({ length: 6 }, () => React.createRef()));

    //condition for diable value
    const isContinueButtonEnabled = otpDigits.every((digit) => digit !== '');

    // state for language change
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("language") || "mr");

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
    };

    useEffect(() => {
        if (reSendMobileNo.length > 0) {
            checkUser();
        }
    }, [])

    // OTP Timer
    useEffect(() => {
        let clear;
        if (timer > 0) {
            clear = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else {
            setResendEnabled(true);
        }
        return () => clearInterval(clear);
    }, [timer]);

    const checkUser = async () => {
        console.log('Check user is called');
        const payload = {
            "mobile": reSendMobileNo,
        }
        try {
            const response = await fetch(
                `${ApiCalling.base_url_v1}${ApiCalling.paths.checkUser}`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(payload),
                }
            );
            const responseData = await response.json();
            setUser(responseData);
        }
        catch (error) {
            console.log("Err while calling checkUser");
        }

    }

    // for hanlder input otp value
    const handleOtpInputChange = (index, value) => {
        if (!isNaN(value) && value !== '' && value >= 0 && value <= 9) {
            const newOtpDigits = [...otpDigits];
            newOtpDigits[index] = value;
            setOtpDigits(newOtpDigits);

            if (value !== '' && index < 5) {
                otpInputRefs.current[index + 1].current.focus();
            }
        }
    };

    // for backspace
    const handleBackspace = (index, e) => {
        if (e.keyCode === 8 && index >= 0) {
            const newOtpDigits = [...otpDigits];
            newOtpDigits[index] = '';
            setOtpDigits(newOtpDigits);
            if (index === 0) {
                otpInputRefs.current[index].current.focus();
            } else {
                otpInputRefs.current[index - 1].current.focus();
            }
        }
    };

    // for call custom hook
    const { getAllLabels } = useGetLabels();

    let enauthenticationScreenLabels, hnauthenticationScreenLabels, mrauthenticationScreenLabels;

    if (getAllLabels && getAllLabels.length > 0) {
        const labels = getAllLabels[0][selectedLanguage?.toLowerCase()];
        if (labels) {
            enauthenticationScreenLabels = labels.authenticationScreen;
            hnauthenticationScreenLabels = labels.authenticationScreen;
            mrauthenticationScreenLabels = labels.authenticationScreen;
        }
    }

    // for verify otp
    const verifyOTP = async () => {
        setLoader(true)
        const verificationCode = otpDigits.join('');
        try {
            const payload = {
                "mobile": reSendMobileNo,
                "verfCode": verificationCode,
            }
            const response = await fetch(
                `${ApiCalling.base_url_v1}${ApiCalling.paths.verifyOtp}`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(payload)
                }
            );
            const responseData = await response.json();
            setLoader(false)
            if (responseData?.message === "Verified successfully") {
                console.log("user", user);
                if (user?.data?.userState === 'registered') {
                    localStorage.setItem('token', user?.data?.token);
                    localStorage.setItem("user", JSON.stringify(user))
                    // navigate(`/event-details/${user.token}/${selectedLanguage}`)
                    handleEaseBuzz();
                    //TODO: Initiate payment based on requeted membership
                    //TODO: Navigate to a page with route params - type of membership
                    //TODO: Let the page initiate the payment
                } else {
                    setLoader(false)
                    showToastSuccess("OTP verified");
                    navigate("/register", {
                        state: {
                            payload: {
                                mobile: reSendMobileNo
                            }
                        }
                    })
                }
            } else if (responseData?.message === "Invalid Code") {
                setLoader(false)
                showToastError("Invalid OTP")
                setOtpDigits(['', '', '', '', '', '']);
            }
        } catch (error) {
            setLoader(false)
            showToastError("error", error)
        }
    }

    const handleReSendOtp = async (reSendMobileNo) => {
        setLoader(true);
        console.log("resendOtp", reSendMobileNo);
        try {
            const responseData = await SendOtp(reSendMobileNo);
            if (responseData?.message === "Verification Code Sent To Mobile") {
                showToastSuccess("OTP sent");
                setResendEnabled(false);
                setTimer(60);
                setOtpDigits(['', '', '', '', '', '']);
            } else {
                setLoader(false)
                showToastError("Error while sending OTP");
            }
        } catch (error) {
            showToastError("Error while sending OTP");
        } finally {
            setLoader(false);
        }
    }

    const handleEaseBuzz = async () => {
        setLoader(true);
        const token = localStorage.getItem('token');
        console.log("calling Intiiate Payment", localStorage.getItem('token'));
        try {
            const result = await fetch(`${ApiCalling.base_url_v1}${ApiCalling.paths.initiatePaymentLogin}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        plan: localStorage.getItem('Plan'),
                        productinfo: `Sri Family Guide ${localStorage.getItem('Plan')}`,
                        udf5: '',
                        udf6: '',
                        udf8: '',
                        udf9: '',
                        udf10: '',
                        furl: 'https://stagingapi.sfg.foxberry.live/v1/pay/paymentResponse',
                        surl: 'https://stagingapi.sfg.foxberry.live/v1/pay/paymentResponse'
                    })
                });
            const response = await result.json();
            if (response?.message === "Already Purchased") {
                showToastSuccess('Already Purchased');
                setLoader(false);
            }
            else if (response?.status === 1) {
                // navigate();
                window.location.href = response?.data
                setLoader(false);
            }
            setLoader(false);

        } catch (Err) {
            console.log("Err while calling Ease Buzz API", Err);
            showToastError("Payment failed, please visit again");
            setLoader(false);
        }
    }

    return (
        <>
            <ReuseCode
                headerImage={headerImage}
                Back={Back}
                familygLogo={familygLogo}
                title={selectedLanguage === "mr" ? mrauthenticationScreenLabels?.title : selectedLanguage === "hn" ? hnauthenticationScreenLabels?.title : enauthenticationScreenLabels?.title}
                changeNo={selectedLanguage === "mr" ? mrauthenticationScreenLabels?.changeNo : selectedLanguage === "hn" ? hnauthenticationScreenLabels?.changeNo : enauthenticationScreenLabels?.changeNo}
                endPoint="/mobilenumber"
                onLanguageChange={handleLanguageChange}
            />


            <div className='mobileinputfield'>
                {selectedLanguage === "mr" ? mrauthenticationScreenLabels?.userMsg : selectedLanguage === "hn" ? hnauthenticationScreenLabels?.userMsg : enauthenticationScreenLabels?.userMsg}
                <Form className="otp-container mt-3">
                    {otpDigits.map((digit, index) => (
                        <Form.Group key={index} controlId={`digit${index + 1}`}>
                            <Form.Control
                                ref={otpInputRefs.current[index]}
                                type="text"
                                maxLength="1"
                                className="otp-input"
                                value={digit}
                                onChange={(e) => handleOtpInputChange(index, e.target.value)}
                                onKeyDown={(e) => handleBackspace(index, e)}
                                inputMode="numeric"
                            />
                        </Form.Group>
                    ))}
                </Form>
                <div className='resentLink mt-4'>
                    <div onClick={() => { handleReSendOtp(reSendMobileNo); setTimer(60) }} className="d-block text-center">
                        <button style={{ color: resendEnabled && timer === 0 ? '#FD6E47' : '#D3D3D3', border: 'none', backgroundColor: 'transparent', fontWeight: '600' }} disabled={!resendEnabled || timer > 0}>
                            {selectedLanguage === "mr" ? "OTP पुन्हा पाठवा" : selectedLanguage === "hn" ? "ओटीपी पुनः भेजें" : "Resend OTP"}
                        </button>
                    </div>
                    <p className='text-center'>{`in ${Math.floor(timer / 60)}:${timer} `}</p>
                </div>

            </div>

            <div style={{ width: "90%", margin: '60px auto' }}>
                <button className={isContinueButtonEnabled ? 'primaryBtn' : 'disabledBtn'} disabled={!isContinueButtonEnabled} onClick={verifyOTP}>

                    {loader ? (
                        <Spinner
                            as="span"
                            animation="border"
                            size="lg"
                            role="status"
                            aria-hidden="true"
                        />
                    ) : (
                        selectedLanguage === "mr" ? mrauthenticationScreenLabels?.continueBtn : selectedLanguage === "hn" ? hnauthenticationScreenLabels?.continueBtn : enauthenticationScreenLabels?.continueBtn
                    )}
                </button>
            </div>

            {/* <ReuseButton button="Continue" endPoint="/setpassword" /> */}

        </>
    )
}

export default Authentication
